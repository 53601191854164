const firebaseConfig = {
    apiKey: 'AIzaSyDzwIUhsdAnjCRgi7L5E5rqTucE58y_Y74',
    authDomain: 'team-gti-management.firebaseapp.com',
    projectId: 'team-gti-management',
    storageBucket: 'team-gti-management.appspot.com',
    messagingSenderId: '607329795954',
    appId: '1:607329795954:web:8a7b736a978297bac467eb',
    measurementId: 'G-TD9R6DDJGQ',
}

const auth0Config = {
    client_id: 'srw99OVKwHclxb1vD7TnRda9K8HI0qvV',
    domain: 'dev-657daapf.us.auth0.com',
}

const CLOUDINARY_API = 'https://api.cloudinary.com/v1_1/cdmmedia/image/upload'

const ENV = 'PROD' // DEV // PROD
const VERSION = '2.0.4'

// Change API code before going PRODUCTION

let API_URI
if (ENV === 'DEV') {
    API_URI = 'http://localhost:8001'
    
} else {
    API_URI = 'https://vrcapi.credentialmanagement.com.au'
}

export { firebaseConfig, auth0Config, CLOUDINARY_API, ENV, VERSION, API_URI }
