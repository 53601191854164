import Axios from 'axios'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { Breadcrumb } from 'app/components'
import React, { 
    useState, 
    useEffect, 
    useCallback 
} from 'react'
import { 
    Backdrop, 
    CircularProgress, 
    Grow, 
    Icon, 
    IconButton, 
    TextField, 
    Tooltip 
} from '@mui/material'
import { Box, styled, useTheme } from '@mui/system'
import { API_URI } from 'config'

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const ApprovedCustomer = ({condition}) => {
    const [tableConfig, setTableConfig] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
    })
    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(false)
    const token = window.localStorage.getItem('accessToken');
    const [searchValue, setSearchValue] = useState('')

    const fetchData = useCallback(async()=>{
        try {
            const {data} = await  Axios.get(`${API_URI}/api/users/complete-await-passes/?page=${tableConfig.page}&rowsPerpage=${tableConfig.rowsPerPage}`, {  headers:{ authorization:`Bearer ${token}` } })
            setUserList(data?.user)
            setTableConfig({ ...tableConfig,  count: data.total }) 
        } catch (error) {
            console.log(error)
        }
    },[tableConfig])

    const sendPass = async (id) => {
        setLoading(true)
        const response = await Axios.post(
            `${API_URI}/api/user/sendwallet/${id}`, 
            null,  
            { headers:{ authorization:`Bearer ${window.localStorage.getItem("accessToken")}` }}
        )
        setTimeout(() => {
            setLoading(false)
        }, 1500);
        fetchData();
    }

    const fetchSearchedValue = useCallback(async ()=>{ 
        const {data} = await Axios.get(`${API_URI}/api/user-find/get/complete-waiting/${searchValue}`, {  headers:{
                  authorization:`Bearer ${token}`
            }
        })
          setUserList(data)
          setTableConfig({ ...tableConfig,  count: data.length }) 
    }, [searchValue])

    useEffect(() => {
        if(searchValue){
            fetchSearchedValue()
        }else{
            fetchData();
        }
    }, [tableConfig.page, tableConfig.rowsPerPage]);

    const { palette } = useTheme()
    const textMuted = palette.text.secondary

    const columns = [
        {
            name: 'memberId',
            label: 'Member Number',
            options: {
                filter: true,
            },
        },
        {
            name: 'firstName',
            label: 'Membership First Name',
            options: {
                filter: true,
            },
        },
        {
            name: 'lastName',
            label: 'Membership Last Name',
            options: {
                filter: true,
            },
        },
        {
            name:"membershiptypeLongName",
            label:"Membership Type",
            options: {
                filter: true,
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
            },
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    const user = userList[dataIndex]
                    return (
                    
                    <FlexBox>
                        <Box flexGrow={1}></Box>
                        <Tooltip title="Edit Profile">
                        <Link to={`/pages/new-customer/${user?._id}`}>
                            <IconButton>
                                <Icon>edit</Icon>
                            </IconButton>
                        </Link>
                        </Tooltip>
                        <Tooltip title="View Profile">
                 
                        <Link to={`/pages/view-customer/${user?._id}`}>
                            <IconButton>
                                <Icon>arrow_right_alt</Icon>
                            </IconButton>
                        </Link>
                        </Tooltip>
                     
                    </FlexBox>
                )},
            },
        },
    ]

    return (
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={()=>{
                    setLoading(false)
                }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Pages', path: '/' },
                        { name: 'Members Ready To Receive Pass' },
                    ]}
                />
            </div>
            <Box overflow="auto">
                <Box minWidth={750}>
                    <MUIDataTable
                        title={'Members Ready Receive Pass'}
                        data={userList}
                        columns={columns}
                        options={{
                            filterType: 'checkbox',
                            draggableColumns:{
                                enabled:true,
                                transitionTime:500
                            },
                            // expandableRows:true,
                            responsive: 'vertical',
                            jumpToPage:true,
                            count:tableConfig.count,
                            selectableRows: "none", // set checkbox for each row
                            search: true, // set search option
                            filter: true, // set data filter option
                            // download: false, // set download option
                            print: false, // set print option
                            pagination: true, //set pagination option
                            viewColumns: true, // set column option
                            elevation: 1,
                            serverSide:true,
                            searchPlaceholder:"Search by Member Number, Barcode or Email",
                            onSearchOpen:()=>{
                                setSearchValue('')
                            },
                            onSearchClose:()=>{
                                setSearchValue('')
                                fetchData()
                            },
                            // viewColumns:true,
                            rowsPerPageOptions: [10, 20, 40, 80, 100],
                            rowsPerPage:tableConfig.rowsPerPage,
                            customSearchRender: (
                                searchText,
                                handleSearch,
                                hideSearch,
                                options
                            ) => {
                                return (
                                    <Grow appear in={true} timeout={300}>
                                      <form onSubmit={(e)=>{
                                        e.preventDefault()
                                        fetchSearchedValue()
                                      }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={searchValue}
                                                placeholder="Search by Member Number, Barcode or Email"
                                                onSubmit={()=>{
                                                    fetchSearchedValue()
                                                }}
                                                onChange={({ target: { value } }) =>
                                                    setSearchValue(value)
                                                }
                                                InputProps={{
                                                    style: {
                                                        paddingRight: 0,
                                                    },
                                                    startAdornment: (
                                                        <Icon
                                                            fontSize="small"
                                                            sx={{ mr: 1 }}
                                                        >
                                                            search
                                                        </Icon>
                                                    ),
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={hideSearch}
                                                        >
                                                            <Icon fontSize="small">
                                                                clear
                                                            </Icon>
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                      </form>
                                    </Grow>
                                )
                            },
                            onChangePage:(state)=>{
                                setTableConfig({
                                    ...tableConfig,
                                    page:state
                                })
                            },
                            onChangeRowsPerPage:(state)=>{
                                setTableConfig({
                                    ...tableConfig,
                                    rowsPerPage:state
                                })
                            }
                        }}
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default ApprovedCustomer
