import { authRoles } from './auth/authRoles'


export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    {
        name: 'Membership',
        icon: 'people',
        auth:authRoles.admin,
        children: [
            {
                name:"Upload Member",
                path:'/pages/upload-member',
                iconText: 'NC',
            },
            {
                name: 'All Member List',
                path: '/pages/customer-list',
                iconText: 'CL',
            },
            {
                name: 'Awaiting Pass',
                path: '/pages/members-awaiting-pass',
                iconText: 'CL',
            },
            {
                name: 'Incomplete Profile',
                path: '/pages/members-awaiting-completion',
                iconText: 'CL',
            },
        ],
    },
    {
        name:'Bulk Pass Management',
        icon: 'settings',
        auth:authRoles.admin,
        children: [
            {
                name: 'Bulk Pass Sender',
                path: '/pages/bulk-pass-sender',
                iconText: 'NC',
            }
        ],
    }
]

export const navigationSA = [
    {
        name: 'Dashboard',
        path: '/dashboard/analytics',
        icon: 'analytics',
    },
    {
        name: 'VRC',
        icon: 'people',
        auth:authRoles.sa,
        children: [
            {
                name: 'Add New Admin',
                path: '/pages/new-client/new',
                iconText: 'NC',
            },
            {
                name: 'VRC Administrators',
                path: '/pages/client-list',
                iconText: 'CL',
                
            },
        ],
    },
    {
        name: 'Membership',
        icon:'card_membership',
        auth:authRoles.sa,
        children:[
            {
                name:"Upload Member",
                path:'/pages/upload-member',
                iconText: 'NC',
            },
            {
                name:"Membership Types",
                path:'/pages/membership-types',
                iconText: 'NC',
            },
            {
                name:"All Members List",
                path:'/pages/customer-list',
                iconText: 'NC',
            },
            {
                name: 'Members Awaiting Pass',
                path: '/pages/members-awaiting-pass',
                iconText: 'NC',
            },
            {
                name: 'Members Awaiting Completion',
                path: '/pages/members-awaiting-completion',
                iconText: 'NC',
            }
        ]
    },
    {
        name: 'Wallet Passes',
        icon: 'wallet',
        auth:authRoles.admin,
        children: [
            {
                name: 'Add New Wallet Pass',
                path: '/pages/wallet-pass/new',
                iconText: 'NC',
            },
            {
                name: 'All Wallet passes',
                path: '/pages/all-wallet-pass',
                iconText: 'CL'
            }
        ],
    },
    {
        name:'Bulk Pass Management',
        icon: 'settings',
        auth:authRoles.admin,
        children: [
            {
                name: 'Bulk Pass Sender',
                path: '/pages/bulk-pass-sender',
                iconText: 'NC',
            }
        ],
    },
    {
        name:'Admins Logins History',
        icon: 'monitoring',
        auth:authRoles.admin,
        children: [
            {
                name: 'All Logs',
                path: '/pages/log-in-monitering',
                iconText: 'NC',
            }
        ],
    }
]