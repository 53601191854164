import React from 'react'

const MatxLogo = ({ className }) => {

    return  (
        <img
            alt="VRC LOGO"
            src="/assets/images/logos/logo.png"
            style={{
                margin:'5px',
                width:"36.7px",
                height:"64px",
                borderRadius:25
            }}
        />
    )
}

export default MatxLogo
