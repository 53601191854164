import Axios from 'axios'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { Breadcrumb } from 'app/components'
import React, { useState, useEffect, useCallback } from 'react'
import {  Chip, Grow, Icon, IconButton, TextField, Tooltip } from '@mui/material'
import { Box, styled, useTheme } from '@mui/system'
import { API_URI } from 'config'
import EmailIcon from '@mui/icons-material/Email';

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const CustomerAwaitingList = ({condition}) => {
    const [tableConfig, setTableConfig] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 10,
    })
    const [searchValue, setSearchValue] = useState('')
    const [userList, setUserList] = useState([]);
    const token = window.localStorage.getItem('accessToken');
    
    const getData = useCallback(async ()=>{
        try {
            const {data} =  await Axios.get(`${API_URI}/api/users/awaiting-completion/?page=${tableConfig.page}&rowsPerpage=${tableConfig.rowsPerPage}`, { headers:{ authorization:`Bearer ${token}`} })
            setUserList(data?.user);
          let newObject = data?.user?.map((item, index)=>{
            return {
                ...item, 
                has_email:item?.account_health?.has_email,
                has_assets:item?.account_health?.has_assets

            }
          })
          setUserList(newObject)
          
            setTableConfig({ ...tableConfig,  count: data.total
            })
        } catch (error) {
            console.log(error)
        }
    }, [tableConfig])

    const fetchSearchedValue = useCallback(async ()=>{ 
        const {data} = await Axios.get(`${API_URI}/api/user-find/get/inComplete/${searchValue}`, {  headers:{
                  authorization:`Bearer ${token}`
            }
        })
          setUserList(data)
          setTableConfig({ ...tableConfig,  count: data.length }) 
    }, [searchValue])
    
    useEffect(() => {
       if(searchValue){
            fetchSearchedValue()
       }else{
        getData();
       }
    }, [tableConfig.page, tableConfig.rowsPerPage])

    const { palette } = useTheme()
    const textMuted = palette.text.secondary

    const columns = [
        {
            name: 'memberId',
            label: 'Member Number',
            options: {
                filter: true,
            },
        },
        {
            name: 'firstName',
            label: 'Membership First Name',
            options: {
                filter: true,
            },
        },
        {
            name: 'lastName',
            label: 'Membership Last Name',
            options: {
                filter: true,
            },
        },
        {
            name:"membershiptypeLongName",
            label:"Membership Type",
            options: {
                filter: true,
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
            },
        },
        {
            name: 'has_email',
            label: 'Has Email?',
            options: {
                filter: true,
                customBodyRenderLite:(dataIndex)=>{
                    const user = userList[dataIndex]
                    
                    return (
                       <Box sx={{ display:"flex", flexDirection:"row" }}>
                        {
                            !user?.account_health?.has_email && (
                                <Chip
                                    label={"No Email"}
                                    size="small"
                                    color='warning'
                                    variant='filled'
                                    icon={<EmailIcon />}
                                />
                            )
                        }
                       </Box>
                    )
                }
            },
        },
        {
            name: 'has_assets',
            label: 'Has Assets?',
            options: {
                filter: true,
                customBodyRenderLite:(dataIndex)=>{
                    const user = userList[dataIndex]
                    
                    return (
                       <Box sx={{ display:"flex", flexDirection:"row" }}>
                        {
                            !user?.account_health?.has_assets && (
                                <Chip
                                    label={"No Assets"}
                                    size="small"
                                    color='error'
                                    variant='filled'
                                    icon={<EmailIcon />}
                                />
                            )
                        }
                       </Box>
                    )
                }
            },
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                customBodyRenderLite: (dataIndex) => {
                    const user = userList[dataIndex]
                    return (
                    
                    <FlexBox>
                        <Box flexGrow={1}></Box>
                        <Tooltip title="Edit Profile">
                            <Link to={`/pages/new-customer/${user?._id}`}>
                                <IconButton>
                                    <Icon>edit</Icon>
                                </IconButton>
                            </Link>
                        </Tooltip>
                        
                        <Tooltip title="View Profile">
                            <Link to={`/pages/view-customer/${user?._id}`}>
                                <IconButton>
                                    <Icon>arrow_right_alt</Icon>
                                </IconButton>
                            </Link>
                        </Tooltip>

                    </FlexBox>
                )},
            },
        },
    ]

    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Pages', path: '/' },
                        { name: 'Members Awaiting Completion' },
                    ]}
                />
            </div>
            <Box overflow="auto">
                <Box minWidth={750}>
                    <MUIDataTable
                        title={'All Members Awaiting Completion'}
                        data={userList}
                        columns={columns}
                        options={{
                            filterType: 'checkbox',
                            draggableColumns:{
                                enabled:true,
                                transitionTime:500
                            },
                            responsive: 'vertical',
                            jumpToPage:true,
                            count:tableConfig.count,
                            selectableRows: "none", 
                            filter: false,
                            print: false,
                            pagination: true,
                            download: true,
                            downloadOptions: {
                              filename: "members-await-completion.csv",
                              separator: ",",
                              filterOptions: { useDisplayedColumnsOnly: true },
                              useDisplayedRowsOnly: true,
                            },
                            viewColumns: true,
                            serverSide:true,
                            elevation: 1,
                            search:true,
                            searchPlaceholder:"Search by Member Number, Barcode or Email",
                            onSearchOpen:()=>{
                                setSearchValue('')
                            },
                            onSearchClose:()=>{
                                setSearchValue('')
                                getData()
                            },
                            rowsPerPage:tableConfig.rowsPerPage,
                            rowsPerPageOptions: [10, 20, 40, 80, 100],
                            customSearchRender: (
                                searchText,
                                handleSearch,
                                hideSearch,
                                options
                            ) => {
                                return (
                                    <Grow appear in={true} timeout={300}>
                                      <form onSubmit={(e)=>{
                                        e.preventDefault()
                                        fetchSearchedValue()
                                      }}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={searchValue}
                                                placeholder="Search by Member Number, Barcode or Email"
                                                onSubmit={()=>{
                                                    fetchSearchedValue()
                                                }}
                                                onChange={({ target: { value } }) =>
                                                    setSearchValue(value)
                                                }
                                                InputProps={{
                                                    style: {
                                                        paddingRight: 0,
                                                    },
                                                    startAdornment: (
                                                        <Icon
                                                            fontSize="small"
                                                            sx={{ mr: 1 }}
                                                        >
                                                            search
                                                        </Icon>
                                                    ),
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={hideSearch}
                                                        >
                                                            <Icon fontSize="small">
                                                                clear
                                                            </Icon>
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                      </form>
                                    </Grow>
                                )
                            },
                            onChangePage:(state)=>{
                                setTableConfig({
                                    ...tableConfig,
                                    page:state
                                })
                            },
                            onChangeRowsPerPage:(state)=>{
                                setTableConfig({
                                    ...tableConfig,
                                    rowsPerPage:state
                                })
                            }
                        }}
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default CustomerAwaitingList
